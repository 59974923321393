<a [routerLink]="['collection', collection.id]">
  <div class="card">
    <div class="card-content">
      <div class="truncate"><b>{{collection.name}}</b></div>
      <ul class="items-text">
        <li class="content"><span class="truncate" *ngIf="items[0]">{{items[0].name}}</span></li>
        <li class="content"><span class="truncate" *ngIf="items[1]">{{items[1].name}}</span></li>
        <li class="content"><span class="truncate" *ngIf="items[2]">{{items[2].name}}</span></li>
        <li class="content"><span class="truncate" *ngIf="items[3]">{{items[3].name}}</span></li>
        <li class="content"><span class="truncate" *ngIf="items[4]">{{items[4].name}}</span></li>
      </ul>
    </div>
  </div>
</a>
