<div class="topbar">
  <app-login class="login-block"></app-login>
</div>
<div class="full-body">
  <div class="responsive-container">
    <router-outlet></router-outlet>
    <!--    <div class="card">-->
<!--      <div class="card-content">-->
<!--        <app-add-item (changes)="reloadItems()"></app-add-item>-->
<!--        <app-list [items]="items"></app-list>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="card">-->
<!--      <div class="card-content">-->
<!--        <app-calibrate-item (changes)="reloadItems()" *ngIf="!!lastItem" [lastItem]="lastItem"></app-calibrate-item>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="card">-->
<!--      <div class="card-content">-->
<!--        <app-pairs (changes)="reloadItems()"></app-pairs>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</div>

